import React, { useState, useEffect } from "react";
import {
	Avatar,
	Brand,
	Divider,
	Dropdown,
	DropdownGroup,
	DropdownItem,
	DropdownList,
	Masthead,
	MastheadBrand,
	MastheadContent,
	MastheadMain,
	MastheadToggle,
	MenuToggle,
	PageToggleButton,
	Toolbar,
	ToolbarContent,
	ToolbarGroup,
	ToolbarItem,
	Flex,
	FlexItem,
	Button,
	Split,
} from "@patternfly/react-core";
import BarsIcon from "@patternfly/react-icons/dist/esm/icons/bars-icon";
import EllipsisVIcon from "@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon";
import imgAvatar from "@patternfly/react-core/src/components/assets/avatarImg.svg";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import config from "../config.json";

const appsUrl = config.appsUrl;
const jobsUrl = config.jobsUrl;
const blogUrl = config.blogUrl;
const labUrl = config.labUrl;
const forumUrl = config.forumUrl;

export default function Header() {
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const [isLogin, setIsLogin] = React.useState(false);
	const [isFullKebabDropdownOpen, setIsFullKebabDropdownOpen] =
		React.useState(false);

	const onDropdownToggle = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	const onDropdownSelect = () => {
		setIsDropdownOpen(false);
	};

	const onFullKebabDropdownToggle = () => {
		setIsFullKebabDropdownOpen(!isFullKebabDropdownOpen);
	};
	const onFullKebabDropdownSelect = () => {
		setIsFullKebabDropdownOpen(false);
	};
	const redirectToRegister = () => {
		window.open("https://e2e.llmops.au/jupyter/basics/");
	};

	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const userDropdownItems = (
		<>
			<DropdownItem key="group 2 profile">My profile</DropdownItem>
			<DropdownItem key="group 2 user">User management</DropdownItem>
			<DropdownItem key="group 2 logout">Logout</DropdownItem>
		</>
	);
	const headerToolbar = (
		<Toolbar
			id="toolbar"
			isFullHeight
			isStatic
			style={{ backgroundColor: "#373A40" }}
		>
			<ToolbarContent>
				<ToolbarGroup
					variant="icon-button-group"
					align={{
						default: "alignRight",
					}}
					spacer={{
						default: "spacerNone",
						md: "spacerMd",
					}}
				>
					{!isLogin ? (
						<Split hasGutter>
							<Button variant="secondary" className="login-button-mobile">
								Register
							</Button>
							<Button
								variant="primary"
								className="login-button-mobile"
								onClick={redirectToRegister}
							>
								Log in
							</Button>
						</Split>
					) : (
						<ToolbarItem
							visibility={{
								md: "hidden",
							}}
						>
							<Dropdown
								isOpen={isFullKebabDropdownOpen}
								onSelect={onFullKebabDropdownSelect}
								onOpenChange={(isOpen) => setIsFullKebabDropdownOpen(isOpen)}
								popperProps={{
									position: "right",
								}}
								toggle={(toggleRef) => (
									<MenuToggle
										ref={toggleRef}
										onClick={onFullKebabDropdownToggle}
										isExpanded={isFullKebabDropdownOpen}
										variant="plain"
										aria-label="Toolbar menu"
									>
										<EllipsisVIcon aria-hidden="true" />
									</MenuToggle>
								)}
							>
								<DropdownGroup key="group 2" aria-label="User actions">
									<DropdownList>{userDropdownItems}</DropdownList>
								</DropdownGroup>
								<Divider />
							</Dropdown>
						</ToolbarItem>
					)}
				</ToolbarGroup>
				<ToolbarItem
					visibility={{
						default: "hidden",
						md: "visible",
					}}
				>
					{!isLogin ? (
						<>
							<Button
								variant="secondary"
								className="login-button-desktop"
								onClick={redirectToRegister}
							>
								Register
							</Button>
							<Button
								variant="primary"
								className="login-button-desktop"
								onClick={redirectToRegister}
							>
								Log in
							</Button>
						</>
					) : (
						<Dropdown
							isOpen={isDropdownOpen}
							onSelect={onDropdownSelect}
							onOpenChange={(isOpen) => setIsDropdownOpen(isOpen)}
							popperProps={{
								position: "right",
							}}
							toggle={(toggleRef) => (
								<MenuToggle
									ref={toggleRef}
									onClick={onDropdownToggle}
									isFullHeight
									isExpanded={isDropdownOpen}
									icon={<Avatar src={imgAvatar} alt="" />}
								>
									Ned Username
								</MenuToggle>
							)}
						>
							<DropdownList>{userDropdownItems}</DropdownList>
						</Dropdown>
					)}
				</ToolbarItem>
			</ToolbarContent>
		</Toolbar>
	);
	return (
		<Masthead
			className="header"
			style={{
				maxWidth: "1440px",
				margin: "0 auto",
				width: "100%",
			}}
		>
			{isMobileView && (
				<MastheadToggle>
					<PageToggleButton variant="plain" aria-label="Global navigation">
						<BarsIcon />
					</PageToggleButton>
				</MastheadToggle>
			)}
			<MastheadMain>
				<Link to="/">
					<MastheadBrand>
						<Brand
							src={logo}
							alt="Ground central"
							heights={{
								default: "108px",
							}}
						/>
					</MastheadBrand>
				</Link>
			</MastheadMain>
			<MastheadContent>
				{!isMobileView && (
					<Flex style={{ width: "100%" }}>
						<FlexItem>
							<a href={appsUrl}>App Store</a>
						</FlexItem>
						<FlexItem>
							<a href={labUrl}>GC-Lab</a>
						</FlexItem>
						<FlexItem>
							<a href={jobsUrl}>Jobs</a>
						</FlexItem>
						<FlexItem>
							<a href={blogUrl}>Blog</a>
						</FlexItem>
						<FlexItem>
							<a href={forumUrl}>Forum</a>
						</FlexItem>
						<FlexItem>
							<a href="/about">About Us</a>
						</FlexItem>
					</Flex>
				)}
				{headerToolbar}
			</MastheadContent>
		</Masthead>
	);
}
